import React, { useCallback, useMemo } from "react";
import "../sass/layout/_sheVentures.scss";

import ventures from "../static/data/journeys.js";
import India from "../static/images/sheVentures/India.mp4";
import Bhutan from "../static/images/sheVentures/Bhutan.mp4";
import SriLanka from "../static/images/sheVentures/SriLanka.mp4";
import { useNavigate } from "react-router-dom";
import useWindowDimensions from "../hooks/useWindowDimensions.js";

const VIDEOS = {
  India,
  SriLanka,
  Bhutan,
};

export const SheVentures = () => {
  const navigate = useNavigate();

  const [rotateLeft, setRotateLeft] = React.useState(false);
  const [rotateRight, setRotateRight] = React.useState(false);
  const [currentCountryIndex, setCurrentCountryIndex] = React.useState(0);
  const switchCountry = React.useCallback(() => {
    setCurrentCountryIndex((prevIndex) => (prevIndex + 1) % ventures.length);
  }, []);

  const goBack = useCallback(() => {
    setCurrentCountryIndex(
      (prevIndex) => (prevIndex - 1 + ventures.length) % ventures.length
    );
  }, []);

  const { width } = useWindowDimensions();

  const currentCountry = ventures[currentCountryIndex].country;

  const countryList = React.useMemo(() => {
    return ventures.map((venture, index) => {
      return (
        <li
          key={index}
          style={{
            fontFamily:
              venture.country === currentCountry ? "FuturaHeavy" : "FuturaBook",
            transition: "all ease-out 0.2",
          }}
        >
          {venture.country === "SriLanka" ? "Sri Lanka" : venture.country}
        </li>
      );
    });
  }, [currentCountry]);

  const destinationList = React.useMemo(() => {
    return ventures[currentCountryIndex].tours.map((place, index) => (
      <li key={index}>{place.destination}</li>
    ));
  }, [currentCountryIndex]);

  const renderDetails = React.useMemo(() => {
    return ventures[currentCountryIndex].details.map((line, index) => (
      <p key={index}>{line}</p>
    ));
  }, [currentCountryIndex]);

  const toggleLeft = useCallback(
    () => setRotateLeft(!rotateLeft),
    [rotateLeft]
  );

  const toggleRight = useCallback(
    () => setRotateRight(!rotateRight),
    [rotateRight]
  );

  const navToDestination = useCallback(
    () => navigate(`/:${currentCountry}`),
    [currentCountry, navigate]
  );

  const renderLeftSide = useMemo(() => {
    if (width < 600) {
      return (
        <div className="ventures-container-left">
          <div className="ventures-container-left-lower-r1">
            <div className="ventures-container-left-lower-r1-h1">
              {currentCountry === "SriLanka" ? "Sri Lanka" : currentCountry}
            </div>
            <ul className="ventures-container-left-lower-r1-list">
              {destinationList}
            </ul>
          </div>
          <div className="ventures-container-left-upper">
            <div className="ventures-container-left-upper-item">
              {renderDetails}
            </div>
          </div>

          <div className="ventures-container-left-lower">
            <div
              className="ventures-container-left-lower-r1"
              style={{ marginBottom: "1rem" }}
            >
              <div
                className="arrow-container"
                style={{ marginLeft: "3rem" }}
                onMouseEnter={toggleLeft}
                onMouseLeave={toggleLeft}
                onClick={goBack}
              >
                <div
                  className={rotateLeft ? "diamond rotate-back" : "diamond"}
                  style={{ backgroundColor: "#97789b" }}
                >
                  <div
                    className="arrow"
                    style={{
                      border: "0.9px solid white",
                    }}
                  >
                    <div
                      className="arrow-before"
                      style={{
                        borderTop: "1px solid white",
                        borderRight: "1px solid white",
                      }}
                    ></div>
                  </div>
                </div>
              </div>

              <div
                className="arrow-container"
                onMouseEnter={toggleRight}
                onMouseLeave={toggleRight}
              >
                <div
                  onClick={switchCountry}
                  className={rotateRight ? "diamond" : "diamond rotate"}
                  style={{
                    backgroundColor: "#97789b",
                    border: "0.8px solid black",
                    marginRight: "3rem",
                  }}
                >
                  <div
                    className="arrow"
                    style={{
                      border: "0.9px solid white",
                    }}
                  >
                    <div
                      className="arrow-before"
                      style={{
                        borderTop: "1px solid white",
                        borderRight: "1px solid white",
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="ventures-container-left-lower-r1"
              style={{ justifyContent: "center" }}
            >
              <button onClick={navToDestination} className="button-greyish">
                Explore more
              </button>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="ventures-container-left">
        <h1>Destinations</h1>
        <div className="ventures-container-left-upper">
          <div className="ventures-container-left-upper-item">
            <div className="ventures-details-wrapper">{renderDetails}</div>

            {/* <p style={{ width: "70%" }}>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie</p> */}
          </div>

          {width > 600 && (
            <div className="ventures-container-left-upper-item">
              <ul
                style={{
                  textAlign: "right",
                  textTransform: "uppercase",
                  textWrap: "nowrap",
                }}
              >
                <div style={{ marginTop: "1rem" }}>{countryList}</div>
              </ul>
            </div>
          )}
        </div>
        <div
          className="ventures-container-left-lower-r1"
          style={{ marginBottom: "1rem" }}
        >
          <div
            className="arrow-container"
            onMouseEnter={toggleLeft}
            onMouseLeave={toggleLeft}
            onClick={goBack}
          >
            <div
              className={rotateLeft ? "diamond rotate-back" : "diamond"}
              style={{ backgroundColor: "#97789b" }}
            >
              <div className="arrow" style={{ border: "0.9px solid white" }}>
                <div
                  className="arrow-before"
                  style={{
                    borderTop: "1px solid white",
                    borderRight: "1px solid white",
                  }}
                ></div>
              </div>
            </div>
          </div>

          <div
            className="arrow-container"
            style={{ marginRight: "0.5rem" }}
            onMouseEnter={toggleRight}
            onMouseLeave={toggleRight}
          >
            <div
              onClick={switchCountry}
              className={rotateRight ? "diamond" : "diamond rotate"}
              style={{
                backgroundColor: "#97789b",
                border: "0.8px solid black",
                marginRight: "3rem",
              }}
            >
              <div className="arrow" style={{ border: "0.9px solid white" }}>
                <div
                  className="arrow-before"
                  style={{
                    borderTop: "1px solid white",
                    borderRight: "1px solid white",
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="ventures-container-left-lower"
          style={{ marginTop: "3rem" }}
        >
          <div className="ventures-container-left-lower-r1">
            <div className="ventures-container-left-lower-r1-h1">
              {currentCountry === "SriLanka" ? "Sri Lanka" : currentCountry}
            </div>
            <ul className="ventures-container-left-lower-r1-list">
              {destinationList}
            </ul>
          </div>

          <div className="ventures-container-left-lower-r1">
            <button
              onClick={navToDestination}
              className="button-greyish"
              style={{ marginLeft: "3rem" }}
            >
              Explore more
            </button>
          </div>
        </div>
      </div>
    );
  }, [
    width,
    countryList,
    currentCountry,
    destinationList,
    goBack,
    navToDestination,
    renderDetails,
    rotateLeft,
    rotateRight,
    switchCountry,
    toggleLeft,
    toggleRight,
  ]);

  const renderVideo = useMemo(() => {
    return (
      <video
        style={{ width: "100%" }}
        src={VIDEOS[currentCountry]}
        autoPlay
        muted
        loop
      />
    );
  }, [currentCountry]);

  return (
    <>
      <div className="ventures-container">
        {renderLeftSide}
        <div
          // style={{ backgroundImage: `url(${images[currentCountry]})` }}
          className="ventures-container-right"
        >
          {renderVideo}
          {/* <span></span> */}
          {/* <div  alt="" className="ventures-container-right-img" /> */}
        </div>
      </div>
    </>
  );
};
