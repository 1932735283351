import { BrowserRouter, Routes, Route } from "react-router-dom";
import Landing from "./pages/landing.js";
import { Navbar } from "./components/Navbar.js";
import { Footer } from "./pages/footer.js";
import { SheVentures } from "./pages/sheVentures.js";
import { SignatureJourneys } from "./components/SignatureJourneys.js";
import { OurEthos } from "./pages/OurEthos.js";
import { Experiences } from "./pages/experiences.js";
import { Banner } from "./components/banner.js";
import { ContactUs } from "./pages/contactUs.js";
import { AboutUs } from "./pages/AboutUs.js";
import { Destination } from "./pages/Destination.js";
import { Testimonials } from "./pages/Testimonials.js";
import useWindowDimensions from "./hooks/useWindowDimensions.js";
import { useEffect, useState } from "react";
import { Product } from "./pages/Product.js";
import { BookNow } from "./components/BookNow.js";
import { FAQ } from "./pages/FAQ.js";

function App() {
  const [landingBannerFontSize, setLandingBannerFontSize] = useState("8rem");
  const [bookNowBannerFontSize, setBookNowBannerFontSize] = useState("2rem");
  const { width } = useWindowDimensions();

  useEffect(() => {
    if (width > 900 && width <= 1200) {
      setLandingBannerFontSize("7rem");
      setBookNowBannerFontSize("1.8rem");
      return;
    } else if (width > 600 && width <= 900) {
      setLandingBannerFontSize("5rem");
      setBookNowBannerFontSize("1.5rem");
      return;
    } else if (width <= 600) {
      setLandingBannerFontSize("3.8rem");
      setBookNowBannerFontSize("1.4rem");
      return;
    }

    setLandingBannerFontSize("8rem");
    setBookNowBannerFontSize("2.5rem");
    return;
  }, [width]);

  return (
    <BrowserRouter>
      <Navbar />

      <Routes>
        <Route
          path="/"
          element={
            <>
              <Landing />
              <OurEthos />
              <SheVentures />
              <SignatureJourneys />
              <Experiences />
              <div style={{ margin: "5rem 0" }}></div>
              <Testimonials />
              <Banner
                heading={"Travel With Us"}
                fontSize={landingBannerFontSize}
                renderIcons={true}
                renderFormButton={true}
                backgroundColor={"#97789B"}
              />

              {/* <ItineraryHighlights /> */}
              {/* <ContactUs /> */}
            </>
          }
        />
        <Route
          path="/about"
          element={
            <>
              <AboutUs />
              <Banner
                heading={"Travel With Us"}
                fontSize={landingBannerFontSize}
                renderIcons={true}
                renderFormButton={true}
                backgroundColor={"#97789B"}
              />
            </>
          }
        />
        <Route
          path="/:destination"
          element={
            <>
              <Destination />
              <Banner
                heading={"Travel With Us"}
                fontSize={landingBannerFontSize}
                renderIcons={true}
                renderFormButton={true}
                backgroundColor={"#97789B"}
              />
            </>
          }
        />
        <Route
          path="/contact"
          element={
            <>
              <ContactUs />
            </>
          }
        />
        <Route
          path="/:destination/:subDestination"
          element={
            <>
              <Product />
              <Banner
                heading={"Travel With Us"}
                fontSize={landingBannerFontSize}
                renderIcons={true}
                renderFormButton={true}
                backgroundColor={"#97789B"}
              />
            </>
          }
        />

        <Route
          path="/book"
          element={
            <>
              <Banner
                width={"70%"}
                renderIcons={false}
                fontSize={bookNowBannerFontSize}
                textTransform={"initial"}
                heading={
                  "Your travel dreams are just a message or a call away. We look forward to helping you make your journey extraordinary."
                }
                backgroundColor={"#97b2b7"}
              />
              <BookNow />
              <Banner
                heading={"Travel With Us"}
                fontSize={landingBannerFontSize}
                renderIcons={true}
                renderFormButton={true}
                backgroundColor={"#97789B"}
              />
            </>
          }
        />
        <Route path="/faq" element={<FAQ />} />
      </Routes>

      <Footer />
    </BrowserRouter>
  );
}

export default App;
