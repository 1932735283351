import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import journeys from "../static/data/journeys.js";
import amritsar from "../static/images/amritsar.jpg";
import kerela from "../static/images/kerela.jpg";
import kashmir from "../static/images/kashmir.jpg";
import ladakh from "../static/images/ladakh.jpg";
import paro from "../static/images/product/paro/paro_intro.jpg";
import bentota from "../static/images/product/bentota/bentota_intro.jpg";
import thimphu from "../static/images/product/thimphu/thimphu_intro.jpg";
import colombo from "../static/images/product/colombo/colombo_intro.jpg";
import kandy from "../static/images/product/kandy/kandy_intro.jpg";
import punakha from "../static/images/product/punakha/punakha_intro.jpg";
import triangle from "../static/images/product/triangle/triangle_intro.jpg";

import "../sass/layout/_destination.scss";
import India from "../static/videos/india_dest.mp4";
import SriLanka from "../static/videos/sri_lanka_dest.mp4";
import Bhutan from "../static/videos/india_dest.mp4";
import "../sass/layout/_signatureJourneys.scss";
import { Banner } from "../components/banner.js";
import { SecondCard } from "../components/journeyCard.js";
import "../sass/layout/_experiences.scss";
import "../sass/base/_typogrophy.scss";

const VIDEOS = {
  India,
  SriLanka,
  Bhutan,
};

const IMAGES = {
  India,
  Bhutan,
  SriLanka,
};

const imgs = {
  kerela,
  kashmir,
  amritsar,
  ladakh,
  paro,
  thimphu,
  punakha,
  colombo,
  kandy,
  bentota,
  triangle,
};

export const Destination = () => {
  const [details, setDetails] = useState(null);
  const { destination } = useParams();

  const [highlightedIndex, setHighlightedIndex] = React.useState(null);

  useEffect(() => {
    setDetails(journeys.find((dest) => destination.slice(1) === dest.country));
    window.scrollTo(0, 0);
  }, [destination]);

  const destinationDetails = React.useMemo(() => {
    if (!details) return null;
    return (
      <div>
        <div className="destination-container-left-upper">
          <video
            style={{ width: "100%" }}
            src={VIDEOS[destination.slice(1)]}
            autoPlay
            muted
            loop
          />
          {/* <h1>{details.destinationIntro}</h1> */}
          {/* <p>{details.destinationIntroSub}</p> */}
        </div>
      </div>
    );
  }, [destination, details]);

  const renderBanner = React.useMemo(() => {
    if (!details) return null;
    return (
      <Banner
        id={"scrollToBanner"}
        width={"50%"}
        renderIcons={false}
        fontSize={"4rem"}
        textTransform={"Uppercase"}
        heading={details.destinationIntro}
        backgroundColor={"#b3c8d3"}
      />
    );
  }, [details]);

  const renderCards = React.useMemo(() => {
    if (!details) return null;
    return details.tours.map((dest, index) => {
      const shouldHighlight = highlightedIndex === index;
      return (
        <SecondCard
          navTo={`/:${destination.slice(1)}/:${dest.destination}`}
          key={index}
          dest={dest}
          img={imgs[dest.img_filename]}
          setHighlightedIndex={setHighlightedIndex}
          shouldHighlight={shouldHighlight}
          index={index}
        />
      );
    });
  }, [details, highlightedIndex, destination]);

  return (
    <>
      <div className="destination-container">
        {destinationDetails}
        <div className="destination-container-right">
          {/* <div className="destination-container-right-destination-image">
            <img alt="thnla" src={IMAGES[destination.slice(1)]} />
          </div> */}
        </div>
      </div>

      {renderBanner}
      <div
        style={{
          justifyContent:
            details && details.tours.length === 3
              ? "space-around"
              : "space-between",
        }}
        className="destination-card-container"
      >
        {renderCards}
      </div>

      {/* <Carousel data={dets} imgs={imgs} /> */}
    </>
  );
};
