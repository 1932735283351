import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import journeys from "../static/data/journeys.js";

import "../sass/layout/_product.scss";
import useWindowDimensions from "../hooks/useWindowDimensions.js";

export const Product = () => {
  const [country, setCountry] = useState(null);
  const [details, setDetails] = useState(null);
  const { destination, subDestination } = useParams();
  const [outroImage, setOutroImage] = useState(null);
  const [mainImage, setMainImage] = useState(null);
  const [increaseImageWidth, setIncreaseImageWidth] = useState(false);
  const navigate = useNavigate();
  const { width } = useWindowDimensions();

  useEffect(() => {
    if (details) {
      setMainImage(details.images.main);
    }
  }, [details]);

  useEffect(() => {
    if (details) {
      if (
        Object.entries(details.images).filter(
          (key) => key[0] !== "outro" && key[0] !== "main"
        ).length <= 3
      ) {
        setIncreaseImageWidth(true);
      } else setIncreaseImageWidth(false);
    }
  }, [details]);

  console.log("Increase image width ", increaseImageWidth);

  useEffect(() => {
    setCountry(journeys.find((dest) => dest.country === destination.slice(1)));

    if (country)
      setDetails(
        country.tours.find(
          (subDest) => subDestination.slice(1) === subDest.destination
        )
      );
  }, [subDestination, destination, country]);

  const renderImageRow = useMemo(() => {
    if (details) {
      const img = Object.entries(details.images).filter(
        (key) => key[0] === "outro"
      );
      setOutroImage(img[0][1]);
      return Object.entries(details.images)
        .filter((key) => key[0] !== "outro" && key[0] !== "main")
        .map((img) => (
          <img
            alt=""
            src={img[1]}
            loading="lazy"
            fetchPriority="low"
            onMouseEnter={() => setMainImage(img[1])}
            onMouseLeave={() => setMainImage(details.images.main)}
          />
        ));
    }
  }, [details]);

  const navToBookingPage = useCallback(() => {
    navigate("/book", {
      state: {
        country: country.country,
        destination: details.destination,
      },
    });
  }, [country, details, navigate]);

  const renderDetailsRow = useMemo(() => {
    if (details) {
      return (
        <>
          <ul className="list-small-text">
            <li>
              <h2 className="list-small-text-heading-coloured">
                Your Wanderluxe Journey
              </h2>
            </li>
            <li>
              <p>
                Our team of travel specialists are waiting to help you book your
                next adventure
              </p>
            </li>
            <li>
              <button onClick={navToBookingPage} className="button-pinkish">
                Book Now
              </button>
            </li>
          </ul>

          <ul className="list-small-text">
            Wanderluxe
            <li>
              <h2>Bespoke Private Experience</h2>
            </li>
            {details.bespoke.map((line, index) => (
              <li key={index}>
                <p>{line}</p>
              </li>
            ))}
          </ul>

          {width < 600 && (
            <ul
              style={{ textAlign: "center" }}
              className="list-small-text bold"
            >
              <li>
                <b>Number of Pax:</b> {details.pax_bespoke}
              </li>
              <li>
                <b>Best time to visit:</b> {details.bestTime_bespoke}
              </li>
            </ul>
          )}

          <ul className="list-small-text">
            Wanderluxe
            <li>
              <h2>CURATED GROUP TOURS</h2>
            </li>
            {details.curated.map((line, index) => (
              <li key={index}>
                <p>{line}</p>
              </li>
            ))}
          </ul>

          {width < 600 && (
            <ul className="list-small-text bold">
              <li>
                <b>Number of Pax:</b> {details.pax_curated}
              </li>
              <li>
                <b>Best time to visit:</b> {details.bestTime_curated}
              </li>
            </ul>
          )}

          {details.special.length ? (
            <ul className="list-small-text">
              Wanderluxe
              <li>
                <h2>SPECIAL EVENT HOLIDAYS</h2>
              </li>
              {details.special.map((line, index) => (
                <li key={index}>
                  <p>{line}</p>
                </li>
              ))}
            </ul>
          ) : null}

          {details.special.length && width < 600 ? (
            <ul className="list-small-text bold">
              <li>
                <b>Number of Pax:</b> <br /> {details.pax_special}
              </li>
              <li>
                <b>Best time to visit:</b> <br /> {details.bestTime_special}
              </li>
            </ul>
          ) : null}
        </>
      );
    }
  }, [details, navToBookingPage, width]);

  const renderItenRow = useMemo(() => {
    if (details && width > 600) {
      return (
        <>
          <ul className="list-small-text">
            <li>
              <button className="button-pinkish">ITINIEARY</button>
            </li>
          </ul>

          <ul className="list-small-text bold">
            <li>
              <b>Number of Pax:</b> <br />
              {details.pax_bespoke}
            </li>
            <li>
              <b>Best time to visit:</b> <br /> {details.bestTime_bespoke}
            </li>
          </ul>

          <ul className="list-small-text bold">
            <li>
              <b>Number of Pax:</b> <br /> {details.pax_bespoke}
            </li>
            <li>
              <b>Best time to visit:</b> <br /> {details.bestTime_curated}
            </li>
          </ul>

          {details.special.length ? (
            <ul className="list-small-text bold">
              <li>
                <b>Number of Pax:</b> <br /> {details.pax_special}
              </li>
              <li>
                <b>Best time to visit:</b> <br /> {details.bestTime_special}
              </li>
            </ul>
          ) : null}
        </>
      );
    }

    return null;
  }, [details, width]);

  const renderOutro = useMemo(
    () => (details ? details.outro : null),
    [details]
  );

  return (
    country &&
    details && (
      <div className="product-main-container">
        <div className="product-container-part-1">
          <div className="product-container-part-1-left">
            <img
              src={mainImage}
              alt=""
              fetchPriority="high"
              className="product-container-part-1-left-img"
              loading="eager"
            />
          </div>
          <div className="product-container-part-1-right">
            <h1 className="product-container-part-1-right-heading">
              {details.description} {details.sub_description}
            </h1>

            <div className="product-container-part-1-right-row1">
              <ul>
                <h1>Country's Info</h1>
                <li>CAPITAL: {details.capital}</li>
                <li>CURRENCY: {details.currency}</li>
                <li>LANGUAGE(s): {details.languages}</li>
                <li>TIME ZONE: {details.tz}</li>
              </ul>

              <ul>
                <h1>CONTACT US TO BOOK NOW</h1>
                <li>
                  Our team of travel specialists are waiting to help you book
                  your next adventure
                </li>
                <li>
                  <button onClick={navToBookingPage} className="button-pinkish">
                    BOOK NOW
                  </button>
                </li>
              </ul>

              <div></div>
            </div>
            <div className="product-container-part-1-right-row2"></div>
          </div>
        </div>
        <div className="product-container-part-2">
          <div className="product-container-part-2-row1">{renderImageRow}</div>
          <div className="product-container-part-2-row2">
            {renderDetailsRow}
          </div>
          <div className="product-container-part-2-row3">{renderItenRow}</div>
        </div>

        <div className="product-container-outro">
          <img src={outroImage} alt="" fetchPriority="low" />
          <h2>{renderOutro}</h2>
        </div>
      </div>
    )
  );
};
