import React from "react";
import wl_logo from "../static/images/WL_LOGO.svg";
import wl_logo_short from "../static/images/logos/wl_logo_short_coloured.svg";
import "../sass/layout/_footer2.scss";
import "../sass/main.scss";
import useWindowDimensions from "../hooks/useWindowDimensions.js";

export const Footer = () => {
  const { width } = useWindowDimensions();

  return (
    <div className="footer">
      <div className="footer-left">
        <div className="footer-left-up">
          <div className="footer-section">
            <div className="footer-section-label">Quick Links</div>
            <div className="footer-section-links">
              <a href="/">Home</a>
              <a href="/aboutUs">About Us</a>
              <a href="/contactUs">Contact Us</a>
              <a href="/tnc">Terms and Conditions</a>
              <a href="/book">Book Now</a>
              <a href="/faq">FAQ</a>
            </div>
          </div>

          <div className="footer-section">
            <div className="footer-section-label">Destinations</div>
            <div className="footer-section-links">
              <a href="/:India">India</a>
              <a href="/:Bhutan">Bhutan</a>
              <a href="/:SriLanka">Sri Lanka</a>
            </div>
          </div>

          <div className="footer-section">
            <div className="footer-section-label">Licence Travel Agents</div>
            <div className="footer-section-links">
              <div>Sailing Seascapes PTE. LTD.</div>
            </div>
            <div className="footer-section-label" style={{ marginTop: "2rem" }}>
              Licence Number
            </div>
            <div className="footer-section-links">
              <div style={{ marginTop: "-2rem" }}>TA03724</div>
            </div>
            <div className="footer-section-links">
              <a href="mailto:info@sailingseascapes.com">
                info@sailingseascapes.com
              </a>
            </div>
          </div>
          <div className="footer-section">
            <div className="footer-section-label">Licence Travel Agents</div>
            <div className="footer-section-links">
              <div>10 Anson Road @05-55</div>
              <div>International Plaza</div>
              <div>Singapore</div>
              <div>079903</div>

              <div style={{ marginTop: "1rem" }}>
                <div>+91 1234567890</div>
                <div>info@ wanderluxe.sg</div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-left-down">
          <object data={wl_logo} type="image/svg+xml">
            WanderLuxe
          </object>
        </div>
      </div>

      <div className="footer-right">
        <div className="footer-section">
          <div className="footer-section-label">Travel Experiences</div>
          <div className="footer-section-links">
            <div>
              Luxury journeys & empowering adventures; exclusively for women
            </div>
            <div className="footer-right-form">
              <label>SUBSCRIBE TO OUR NEWSLETTER :</label>
              <div className="footer-right-form-content">
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="someone@gmail.com"
                />
                <button className="footer-button">Subscribe</button>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-section">
          <div className="footer-section-label">Follow Us</div>
          <div className="footer-section-links">
            <a href="/wanderluxe">@WANDERLUXE</a>
          </div>
        </div>
        <div
          className="footer-section logo"
          style={{ justifyContent: "space-between" }}
        >
          <object data={wl_logo_short} type="image/svg+xml">
            Wanderluxe
          </object>
          <div className="footer-copyright">
            <div>© Copyright, Wanderluxe SG 2024</div>
            <div>Designed by Black Prism Designs</div>
          </div>
        </div>
      </div>
    </div>
  );
};
