import React, { useCallback, useMemo } from "react";
import travelHeart from "../static/images/travelHeart.png";
import "../sass/layout/_banner.scss";
import "../sass/base/_base.scss"
import { useNavigate } from "react-router-dom";


export const Banner = ({ style, id, width, renderIcons, heading, backgroundColor, textTransform, fontSize, renderFormButton }) => {
    const navigate = useNavigate();

    const navToBook = useCallback(() => navigate("/book"), [navigate]);

    const renderHeading = useMemo(() => {
        if (renderFormButton) {
            return (
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%", margin: "3rem" }}>
                    <h1 style={{ width: width, fontSize: fontSize, textTransform: textTransform }}>
                        {heading}
                    </h1>

                    {window.location.href.indexOf("book") === -1 &&
                        <button className="button-pinkish" onClick={navToBook} style={{ alignSelf: "center", boxShadow: "0 0.5rem 1rem rgba(0, 0, 0, 0.2);" }}>Book Now</button>
                    }

                </div>
            );
        }

        return (
            <h1 style={{ width: width, fontSize: fontSize, textTransform: textTransform }}>
                {heading}
            </h1>
        )
    }, [fontSize, heading, navToBook, renderFormButton, textTransform, width]);
    return (
        <div id={id ? id : ""} style={{ ...style, height: renderFormButton ? "inherit" : "20vh", backgroundColor: backgroundColor, textAlign: "center" }} className="banner-container">
            {/* {renderIcons && <img style={{ margin: "0 1rem" }} src={travelHeart} alt="" />} */}
            {renderHeading}
            {/* <h1 style={{ width: width, fontSize: fontSize, textTransform: textTransform }}>
                {heading}
            </h1>
            {renderFormButton && <button style={{ position: "absolute", bottom: 0 }} className="button-pinkish">Book Now</button>} */}
            {/* {renderIcons && <img style={{ margin: "0 1rem" }} src={travelHeart} alt="" />} */}
        </div>
    )

}