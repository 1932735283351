import React from "react";
import ethos from "../static/data/ethos.js";
import "../sass/layout/_ourEthos.scss";
import { useNavigate } from "react-router-dom";
import useWindowDimensions from "../hooks/useWindowDimensions.js";

export const OurEthos = () => {
  const navigate = useNavigate();

  const navigateToAbout = () => navigate("/about");

  const { width } = useWindowDimensions();

  return (
    <div className="ethos-container">
      <h1>Our Ethos</h1>
      <div className="ethos-container-cards">
        {ethos.map((quality, index) => {
          return (
            <div className="ethos-column">
              <ul>
                <img src={quality.image} alt={quality.heading} />
              </ul>
              <ul className="ethos-column-details">
                <li className="wander">Wanderluxe</li>
                <li className="ethos-column-details-heading">
                  <h2>{quality.heading}</h2>
                </li>
                <li className="ethos-column-details-description">
                  <h2>{quality.description}</h2>
                </li>
              </ul>
            </div>
          );
        })}
      </div>
      <button
        onClick={navigateToAbout}
        className="button-greyish"
        style={{ alignSelf: width < 600 && "center" }}
      >
        About us
      </button>
    </div>
  );
};
