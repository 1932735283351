import React from "react";
import "../sass/layout/_experiences.scss";
import "../sass/base/_typogrophy.scss";
import experiences_1 from "../static/images/experiences_1.jpg";
import experiences_2 from "../static/images/experiences_2.jpg";
import experiences_3 from "../static/images/experiences_3.jpg";
import { Carousel } from "../components/Carousel.js";

const EXPERIENCES = [
  {
    img: experiences_1,
    heading: "Luxury Journeys",
    content: (
      <div className="experiences">
        <div className="experiences-heading">
          <h1>Luxury Journeys</h1>
        </div>
      </div>
    ),
  },
  {
    img: experiences_3,
    heading: "Empowering Adventures",
    content: (
      <div className="experiences">
        <div className="experiences-heading">
          <h1>Empowering Adventures</h1>
        </div>
      </div>
    ),
  },
  {
    img: experiences_2,
    heading: "Exclusively for Women",
    content: (
      <div className="experiences">
        <div className="experiences-heading">
          <h1>Exclusively for Women</h1>
        </div>
      </div>
    ),
  },
];

const IMAGES = {
  experiences_1,
  experiences_2,
  experiences_3,
};

export const Experiences = () => {
  return <Carousel data={EXPERIENCES} addMargin={true} imgs={IMAGES} />;
};
