import React, { useEffect, useState } from "react";
import "../sass/layout/_navbar.scss";
import "../sass/main.scss";
import { useNavigate } from "react-router-dom";
import wl_logo_expanded from "../static/images/WL_LOGO.svg";
import wl_logo from "../static/images/logos/WL_Logo-05.svg";

const inAnimation = {
  // transition: "all 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.55);",
  animationFillMode: "forwards",
};
const outAnimation = {
  // transition: "all 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.55);",
  // animationFillMode: "forwards",
  // translate: "rotate(45deg)",
  display: "none",
  opacity: 0,
};

export const Navbar = () => {
  const navigate = useNavigate();
  const [displayNone, setDisplayNone] = useState(true);

  const toggleDisplay = React.useCallback(() => {
    setTimeout(
      () => {
        setDisplayNone(!displayNone);
      },
      displayNone ? 100 : 500
    );
  }, [displayNone]);

  const navToHome = React.useCallback(() => {
    navigate("/");
    window.scrollTo(0, 0);
    // toggleDisplay();
  }, [navigate]);
  const [changeLogo, setChangeLogo] = useState(false);

  const [displayVentures, setDisplayVentures] = useState(false);

  useEffect(() => {
    setDisplayVentures(false);
  }, []);

  const onVenturesEnter = () => {
    setDisplayVentures(true);
  };
  const onVenturesLeave = () => {
    setDisplayVentures(false);
  };

  return (
    <div className="nav-container">
      <div onClick={navToHome} className="nav-logo-box">
        <img
          onMouseEnter={() => setChangeLogo(true)}
          onMouseLeave={() => setChangeLogo(false)}
          src={changeLogo ? wl_logo_expanded : wl_logo}
          alt="Logo"
          className="nav-logo"
        />
      </div>

      <div className="navigation">
        <input
          autoComplete="off"
          onClick={toggleDisplay}
          className="navigation-checkbox"
          type="checkbox"
          id="navi-toggle"
        />
        <label htmlFor="navi-toggle" className="navigation-button">
          <span className="navigation-icon">&nbsp;</span>
        </label>
        <div className="navigation-background">&nbsp;</div>

        <nav className={`navigation-nav`}>
          <div
            style={{
              top: displayNone ? 0 : "50%",
              display: displayNone ? "none" : "flex",
              flexDirection: "column",
            }}
            className="navigation-list"
          >
            <div className="navigation-item" onMouseEnter={onVenturesLeave}>
              <a href="/about" className="navigation-link">
                <span>About Us</span>
              </a>
            </div>
            <div
              className="navigation-item"
              onMouseEnter={onVenturesEnter}
              onClick={() => setDisplayVentures(!displayVentures)}
            >
              <a href="#" className="navigation-link">
                <span>She Ventures</span>
              </a>
              <div
                className="navigation-list-sub"
                style={displayVentures ? inAnimation : outAnimation}
              >
                <div className="navigation-item">
                  <a href="/:India" className="navigation-link">
                    <span>India</span>
                  </a>
                </div>
                <div className="navigation-item">
                  <a href="/:Bhutan" className="navigation-link">
                    <span>Bhutan</span>
                  </a>
                </div>
                <div className="navigation-item">
                  <a href="/:SriLanka" className="navigation-link">
                    <span>Sri Lanka</span>
                  </a>
                </div>
              </div>
            </div>
            <div className="navigation-item" onMouseEnter={onVenturesLeave}>
              <a href="/contact" className="navigation-link">
                <span>Contact Us</span>
              </a>
            </div>
            <div className="navigation-item" onMouseEnter={onVenturesLeave}>
              <a href="/book" className="navigation-link">
                <span>Book Now</span>
              </a>
            </div>
            <div
              style={{ marginTop: "10rem" }}
              onClick={navToHome}
              className="navigation-item"
            >
              <label htmlFor="navi-toggle">
                <img
                  id="navi-toggle"
                  src={wl_logo_expanded}
                  style={{ cursor: "pointer" }}
                  alt=""
                />
              </label>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};
